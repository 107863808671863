import { getAuth } from "firebase/auth"
import { AssetsService, AuthenticatedHttp, FirebaseTokenLoader } from "telarya-sdk"

import generateEndpoint from "@/endpoint"

class CompanyDriversService {
  static cachedDrivers = null
  static sdkService = null
  static isInitialized = false
  static isFetching = false
  static fetchPromise = null

  static initialize() {
    if (CompanyDriversService.isInitialized) {
      return
    }

    CompanyDriversService.sdkService = new AssetsService(
      generateEndpoint("assets"),
      new AuthenticatedHttp(new FirebaseTokenLoader(getAuth()))
    )

    // Load cachedDrivers from localStorage: this allows the first caller to have some data without awaiting
    const storedDrivers = localStorage.getItem("driversForTenant")
    if (storedDrivers) {
      try {
        CompanyDriversService.cachedDrivers = JSON.parse(storedDrivers)
        console.log("Loaded drivers from localStorage")
      } catch (error) {
        console.error("Error parsing drivers from localStorage:", error)
        CompanyDriversService.cachedDrivers = null
      }
    }

    // Start fetching drivers in the background
    CompanyDriversService.fetchDrivers()
      .then(() => console.log("Fetched drivers and updated cache"))
      .catch((error) => console.error("Error fetching drivers:", error))

    CompanyDriversService.isInitialized = true
  }

  static async fetchDrivers() {
    if (CompanyDriversService.isFetching) {
      // If already fetching, return the existing promise
      return CompanyDriversService.fetchPromise
    }

    CompanyDriversService.isFetching = true
    CompanyDriversService.fetchPromise = (async () => {
      try {
        console.log("Fetching drivers from server...")
        const drivers = await CompanyDriversService.sdkService.getDriversForTenant()

        CompanyDriversService.cachedDrivers = drivers

        // Update localStorage with the new drivers data
        localStorage.setItem("driversForTenant", JSON.stringify(drivers))
        console.log("Updated drivers cache and localStorage")
      } catch (error) {
        console.error("Failed to fetch drivers:", error)
      } finally {
        CompanyDriversService.isFetching = false
        CompanyDriversService.fetchPromise = null
      }
    })()

    return CompanyDriversService.fetchPromise
  }

  static getDrivers() {
    if (!CompanyDriversService.isInitialized) {
      CompanyDriversService.initialize()
    }
    return CompanyDriversService.cachedDrivers || []
  }

  static getDriver(userId) {
    const drivers = CompanyDriversService.getDrivers()
    return drivers.find((d) => d.id === userId)
  }
}

export default CompanyDriversService
