const generateEndpoint = (service) => {
  let env = ""
  /* eslint-disable no-fallthrough */
  switch (process.env.VUE_APP_DEVELOPMENT_MODE?.toLowerCase()) {
    case "local":
      if (service === "jobs") return "http://localhost:5125"
      if (service === "finances") return "http://localhost:5135"
      if (service === "messaging") return "http://localhost:5159"
      if (service === "locations") return "http://localhost:5018"
      if (service === "assets") return "http://localhost:5010"
      break
    case "development":
      env = "dev"
      if (service === "jobs") return "https://api.staging.telarya.be:5125"
      if (service === "finances") return "https://api.staging.telarya.be:5135"
      if (service === "messaging") return "https://api.staging.telarya.be:5159"
      if (service === "locations") return "https://api.staging.telarya.be:5018"
      if (service === "assets") return "https://api.staging.telarya.be:5010"
      break
    case "test":
      env = "uat"
      break
  }
  if (service === "jobs") return "https://api.telarya.be:5125"
  if (service === "finances") return "https://api.telarya.be:5135"
  if (service === "messaging") return "https://api.telarya.be:5159"
  if (service === "locations") return "https://api.telarya.be:5018"
  if (service === "assets") return "https://api.telarya.be:5010"
}

export default generateEndpoint
