import { createI18n } from "vue-i18n"

import locales from "@/locales"

const messages = locales

export const i18n = createI18n({
  locale: localStorage.getItem("language") || "nl",
  fallbackLocale: "en",
  messages
})
