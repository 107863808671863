import { createRouter, createWebHistory } from "vue-router"

import storage from "../js/storage"
import authenticatedRoutes from "./authenticated"
import authenticationRoutes from "./authentication"

const routes = [
  {
    path: "/start",
    name: "Start",
    redirect: () => (storage.getters.user.loggedIn ? { name: "Index" } : { name: "Auth" })
  },
  ...authenticationRoutes,
  ...authenticatedRoutes
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.user = new Promise(() => {})

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = storage.getters.user.loggedIn
  if (
    (isLoggedIn &&
      to.matched.filter(function (route) {
        return route.name === "Auth"
      }).length === 0) ||
    (!isLoggedIn &&
      to.matched.filter(function (route) {
        return route.name === "Auth"
      }).length > 0)
  ) {
    if (to.path.toUpperCase().includes("OVERLORD")) {
      const user = await router.user
      await user.loadingData
      if (!user.isOverlord) return next({ name: "Index" })
    }
    next()
  } else if (isLoggedIn) next({ name: "Index" })
  else next({ name: "Auth" })
})

export default router
