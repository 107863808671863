import { createStore } from "vuex"

import { authentication } from "./storage-modules/authentication.js"

const storage = createStore({
  modules: {
    authentication
  }
})

export default storage
