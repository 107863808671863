import { httpsCallable } from "@firebase/functions"

class BaseService {
  firebaseFunctions

  constructor(firebaseFunctions) {
    this.firebaseFunctions = firebaseFunctions
  }

  firehttp(functionName, data) {
    return httpsCallable(this.firebaseFunctions, functionName)(data)
  }
}

export default BaseService
