/**
 * Replaces a sub string.
 *
 * @param {Number} index The index at which to replace the string.
 * @param {String} replacement The replacement to be set.
 * @returns The string with the replacement at the given index.
 */
String.prototype.replaceAt = function (index, replacement) {
  return this.substring(0, index) + replacement + this.substring(index + replacement.length)
}

export default {}
