import { getAuth } from "firebase/auth"
import { doc, onSnapshot } from "firebase/firestore"
import { reactive } from "vue"

import { permissionsForEmployee } from "@/js/Rules"
import Updater from "@/models/Updater"

class Company {
  id
  #database

  #thisProxy

  constructor(database, id) {
    this.#database = database
    this.id = id

    this.#thisProxy = reactive(this)

    this.loadData()

    return this.#thisProxy
  }

  loadData() {
    this.loadCompany()
    this.loadRole()
  }

  previousCompanyUnsubscribe = null
  loadCompany() {
    if (this.previousCompanyUnsubscribe != null) this.previousCompanyUnsubscribe()
    this.previousCompanyUnsubscribe = onSnapshot(
      doc(this.#database, "companies/" + this.id),
      (liveSnapshot) => {
        const data = liveSnapshot.data()
        Updater.update(this.#thisProxy, data)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  previousRoleUnsubscribe = null
  loadRole() {
    if (this.previousRoleUnsubscribe != null) this.previousRoleUnsubscribe()
    this.previousRoleUnsubscribe = onSnapshot(
      doc(this.#database, "companies/" + this.id + "/employees/" + getAuth().currentUser.uid),
      (liveSnapshot) => {
        const data = liveSnapshot.data()
        this.#thisProxy.permissions = permissionsForEmployee(data)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  onDestroy() {}
}

export default Company
