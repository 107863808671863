export const authentication = {
  /*
          Defines the state being monitored for the module.
        */
  state: {
    user: {
      loggedIn: false,
      data: null
    }
  },

  /*
          Defines the actions used to retrieve the data.
        */
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null)
      if (user) {
        commit("SET_USER", {
          id: user.uid,
          displayName: user.displayName,
          email: user.email
        })
      } else {
        commit("SET_USER", null)
      }
    }
  },

  /*
          Defines the mutations used
        */
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },
    SET_USER(state, data) {
      state.user.data = data
    }
  },

  /*
          Defines the getters used by the module
        */
  getters: {
    user(state) {
      return state.user
    }
  }
}
