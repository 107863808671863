import { doc, onSnapshot } from "firebase/firestore"
import { reactive } from "vue"

import { permissionsForEmployee } from "@/js/Rules"
import Updater from "@/models/Updater"

class User {
  id
  #database
  companyId

  #thisProxy

  constructor(database, id, companyId) {
    this.#database = database
    this.id = id
    this.companyId = companyId

    this.#thisProxy = reactive(this)

    this.loadData()

    return this.#thisProxy
  }

  loadData() {
    this.#thisProxy.loadingData = Promise.all([this.loadUser(), this.loadEmployee(), this.loadPermissions()])
  }

  previousUserUnsubscribe = null
  loadUser() {
    return this.loadMainData(`users/${this.id}/account/${this.id}`, "previousUserUnsubscribe")
  }

  previousEmployeeUnsubscribe = null
  loadEmployee() {
    if (this.companyId == null) return Promise.resolve()
    return this.loadMainData(`companies/${this.companyId}/employees/${this.id}`, "previousEmployeeUnsubscribe")
  }

  loadMainData(path, unsubscribingName) {
    return new Promise((resolve, reject) => {
      if (this[unsubscribingName] != null) this[unsubscribingName]()
      this[unsubscribingName] = onSnapshot(
        doc(this.#database, path),
        (liveSnapshot) => {
          const data = liveSnapshot.data()
          Updater.update(this.#thisProxy, data)
          resolve()
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  }

  previousPermissionsUnsubscribe = null
  loadPermissions() {
    return new Promise((resolve, reject) => {
      if (this.previousPermissionsUnsubscribe != null) this.previousPermissionsUnsubscribe()
      if (this.companyId == null) return
      this.previousPermissionsUnsubscribe = onSnapshot(
        doc(this.#database, `companies/${this.companyId}/employees/${this.id}`),
        (liveSnapshot) => {
          const data = liveSnapshot.data()
          if (data == null) return
          this.#thisProxy.permissions = permissionsForEmployee(data)
          this.#thisProxy.roles = data.roles
          if (data.isDriver !== false && data.isDriver !== true) this.#thisProxy.isDriver = data.role === "driver"
          else this.#thisProxy.isDriver = data.isDriver
          resolve()
        },
        (error) => {
          console.error(error)
          reject(error)
        }
      )
    })
  }

  onDestroy() {}
}

export default User
