const context = require.context(`.`, true, /.json$/)

const keys = context.keys()

const ignoreLocale = ["bg", "az", "fr"]
const all = {}
keys.forEach((key) => {
  const fileName = key.replace(`./`, "")
  const resource = require(`./${fileName}`)
  const locale = fileName.replace(".json", "")
  if (ignoreLocale.some((val) => locale.includes(val))) return
  all[locale] = JSON.parse(JSON.stringify(resource))
})

export default all
