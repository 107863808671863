export default [
  {
    path: "/auth",
    name: "Auth",
    redirect: { name: "Login" },
    component: () => import("../views/layouts/UnAuthenticated.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("../views/pages/auth/Login.vue")
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        component: () => import("../views/pages/auth/ForgetPassword.vue")
      }
    ]
  }
]
