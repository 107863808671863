/**
 * Compare function
 * @name ComparePredicate
 * @function
 * @param {T} value
 * @param {number} index
 * @param {T[]} obj
 * @returns {unknown}
 */

/**
 * Remove all objects matching the predicate using @see findIndex .
 * @param {ComparePredicate} predicate find calls predicate once for each element of the array, in ascending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findIndex immediately returns that element index. Otherwise, findIndex returns -1.
 * @param {any?} thisArg If provided, it will be used as the this value for each invocation of
 * predicate. If it is not provided, undefined is used instead.
 */
// eslint-disable-next-line no-extend-native
Array.prototype.removeAll = function (predicate, thisArg = null) {
  let index
  while ((index = this.findIndex(predicate, thisArg)) > -1) this.splice(index, 1)
}

export default {}
