import "./StringExtensions"
import "./ArrayExtensions"

JSON.stringifyCircular = (value, replacer, space) => {
  const cache = []
  return JSON.stringify(
    value,
    (key, value) => {
      if (typeof value === "object" && value !== null) {
        // Duplicate reference found, discard key
        if (cache.includes(value)) return

        // Store value in our collection
        cache.push(value)
      }
      if (replacer != null) replacer.call(this, key, value)
      return value
    },
    space
  )
}

export default {}
