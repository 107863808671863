import "./styles/quasar.scss"
import "@quasar/extras/roboto-font/roboto-font.css"
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css"

import iconSet from "quasar/icon-set/fontawesome-v5.js"

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {},
  iconSet
}
