export const permissionCategories = {
  driver: {
    permissions: ["vehicles.view", "orders.view"]
  },
  planner: {
    permissions: [
      "planning.view",
      "planning.update",
      "users.view",
      "calendar.view",
      "calendar.update",
      "vehicles.update",
      "vehicles.create",
      "companies.create",
      "companies.update",
      "companies.view",
      "locations.create",
      "locations.update",
      "locations.view",
      "orders.cost-categories.view",
      "orders.cost-categories.update",
      "companies.cost-categories.view",
      "companies.cost-categories.update",
      "company.profile.view",
      "company.profile.account.view",
      "company.profile.cost-categories.view",
      "company.profile.cost-categories.update",
      "orders.update",
      "orders.create",
      "orders.delete",
      "orders.billing"
    ],
    inherit: "driver"
  },
  admin: {
    permissions: [
      "company.profile.billing.view",
      "company.profile.billing.edit",
      "users.update",
      "users.create",
      "company.profile.account.edit",
      "company.profile.cost-categories.delete"
    ],
    inherit: "planner"
  },
  owner: {
    permissions: ["users.owner.update"],
    inherit: "admin"
  }
}

export const permissions = []
  .concat(...Object.values(permissionCategories).map((item) => item.permissions))
  .filter(function (value, index, array) {
    return array.indexOf(value) === index
  })

export function permissionsForCategory(category) {
  const obj = permissionCategories[category]
  let permissions = obj?.permissions || []
  if (obj?.inherit) permissions = permissions.concat(...permissionsForCategory(obj.inherit))
  return permissions.filter(function (value, index, array) {
    return array.indexOf(value) === index
  })
}

export function permissionsForEmployee(employeeData) {
  let result = []
  if (
    employeeData != null &&
    employeeData.role != null &&
    employeeData.permissions == null &&
    employeeData.roles == null
  )
    result = permissionsForCategory(employeeData.role)
  else if (employeeData?.roles != null) {
    result = employeeData?.permissions != null ? employeeData.permissions : []
    employeeData.roles.forEach((role) => {
      result = result.concat(...permissionsForCategory(role))
    })
  } else result = employeeData?.permissions != null ? employeeData.permissions : []

  return result.filter(function (value, index, array) {
    return array.indexOf(value) === index
  })
}

export function hasPermission(user, key) {
  return user?.permissions?.includes(key)
}
