const update = (obj, data) => {
  if (obj == null) return
  for (const key in data)
    if (data[key] instanceof Object) {
      if (obj[key] == null || !(obj[key] instanceof Object)) obj[key] = data[key]
      else update(obj[key], data[key])
    } else obj[key] = data[key]
}

export default { update }
