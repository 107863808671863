import BaseService from "./BaseService"

class CompaniesService extends BaseService {
  /**
   *
   * @param {string} vat
   * @returns Promise<Boolean>
   */
  async verifyVAT(vat) {
    const company = await this.findCompany(vat)
    return company.result?.valid === true
  }

  /**
   *
   * @param {string} eori
   * @returns Promise<Boolean>
   */
  async verifyEORI(eori) {
    const result = await this.firehttp("verifyEORI", {
      eori
    })
    return result.data.valid !== false
  }

  /**
   *
   * @param {string} eori
   * @returns Promise<Company | null>
   */
  async loadCompanyForEORI(eori) {
    const result = await this.firehttp("verifyEORI", {
      eori
    })
    return result.data.valid !== false ? result.data : null
  }

  /**
   *
   * @param {string} vat
   * @returns Promise<VatCompany | null>
   */
  async loadCompanyForVat(vat) {
    const company = await this.findCompany(vat)
    if (company.result?.valid !== true) return null
    return new VatCompany(company)
  }

  /**
   *
   * @param {string} vat
   * @returns Promise<{ error: any, result: any }>
   */
  findCompany(vat) {
    return new Promise((resolve, reject) => {
      this.firehttp("verifyVAT", {
        vat
      })
        .then((res) => {
          if (res.data.data?.valid === true)
            resolve({
              error: res.data.error,
              result: res.data.data
            })
          else reject(res.data.error?.code)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

class VatCompany {
  vat
  vatNumber
  country
  name
  address

  constructor(data) {
    this.vatNumber = data.result.vatNumber
    this.country = data.result.countryCode?.toUpperCase()
    this.vat = this.country + this.vatNumber
    this.name = data.result.name
    this.address = data.result.address
  }
}

export default CompaniesService
