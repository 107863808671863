export default function (routeKey, mainKey, loadComponent) {
  const myLoadComponent = function (type) {
    let result = null
    if (loadComponent != null) result = loadComponent(type)

    if (result != null) return result

    switch (type) {
      case "list":
        return import(`../views/pages/${mainKey}/list.vue`)
      case "create":
        return import(`../views/pages/${mainKey}/create.vue`)
      case "detail":
        return import(`../views/pages/${mainKey}/details.vue`)
      case "update":
        return import(`../views/pages/${mainKey}/create.vue`)
      default:
        console.error("crud load component failed for type:", type)
        break
    }
  }
  return [
    {
      path: "/" + mainKey,
      name: routeKey,
      component: () => myLoadComponent("list")
    },
    {
      path: "/" + mainKey + "/create",
      name: routeKey + ".Create",
      component: () => myLoadComponent("create")
    },
    {
      path: "/" + mainKey + "/details/:id",
      name: routeKey + ".View",
      component: () => myLoadComponent("detail")
    },
    {
      path: "/" + mainKey + "/edit/:id",
      name: routeKey + ".Edit",
      component: () => myLoadComponent("update")
    }
  ]
}
